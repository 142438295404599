<template>
  <div class="table-page">
    <ld-search-bar :formItems="searchForm" v-model="searchData" @search="onSearch"> </ld-search-bar>
    <ld-table
      rightMenuWidth="120px"
      :handleBarVisible="false"
      :columns="tableColumns"
      :tableData="tableData"
      :loading="tableLoading"
      @update-data="initTableData"
    >
      <template v-slot:rightMenu="{ scope }">
        <table-button @click="onEdit(scope.row)"> 修改大屏 </table-button>
      </template>
    </ld-table>
    <UpdateScreenDialog
      v-if="updateScreenDialogVisible"
      :detail="currentRow"
      @close="updateScreenDialogVisible = false"
      @success="saveSuccess"
    ></UpdateScreenDialog>
  </div>
</template>

<script>
import UpdateScreenDialog from "./components/UpdateScreenDialog";
import { ApiMacQuery } from "@/api/macQuery";

export default {
  name: "MacQuery",
  components: {
    UpdateScreenDialog
  },
  props: {},
  data() {
    return {
      searchForm: {
        screenMac: {
          label: "大屏MAC",
          type: "input"
        }
      },
      searchData: {},
      tableColumns: [
        {
          label: "项目名称",
          prop: "projectName"
        },
        {
          label: "楼栋",
          prop: "buildingCode"
        },
        {
          label: "单元",
          prop: "unitCode"
        },
        {
          label: "房号",
          prop: "doorplate"
        },
        {
          label: "绑定状态",
          prop: "bindStatusStr"
        },
        {
          label: "大屏IP地址",
          prop: "ip"
        },
        {
          label: "大屏MAC地址",
          prop: "screenMac"
        }
      ],
      tableData: [],
      tableLoading: false,
      updateScreenDialogVisible: false,
      currentRow: null
    };
  },
  methods: {
    async initTableData() {
      if (!this.searchData.screenMac) {
        this.tableData = [];
        return;
      }
      this.tableLoading = true;
      const res = await ApiMacQuery.list(this.searchData.screenMac).finally(() => {
        this.tableLoading = false;
      });
      this.tableData = res;
    },
    onSearch() {
      this.initTableData();
    },
    onEdit(row) {
      this.currentRow = row;
      this.updateScreenDialogVisible = true;
    },
    saveSuccess() {
      this.updateScreenDialogVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
