import request from "@/plugins/axios";

export const ApiMacQuery = {
  list(screenMac) {
    return request({
      url: "/homeauto-center-device/device/web/family/list/mac",
      method: "get",
      params: {
        screenMac
      }
    });
  }
};
